<template>
  <div class="container"  v-wechat-title="$route.meta.title=brandInfo.app_name" >
    <div class="header">
      <!--  -->
      <div class="header-avatar">
        <van-image class="header-avatar-img" :src="brandInfo.app_avatar" />
      </div>
    </div>
    <div class="form">
      <div class="form-field van-cell van-cell--center van-field">
        <van-button style="margin-top:35px;" round type="primary" block @click="onSubmit">微信快捷登陆</van-button>
      </div>
      <div class="form-field van-cell van-cell--center van-field">
        <a href="javascript:void(0);" @click="onClsoe" style="text-align: center;width: 100%;color: #adabab;">取消</a>
      </div>
    </div>

    <div style="position: absolute;bottom: 50px;text-align: center;width: 100%;margin: 0 auto;color: rgb(173, 171, 171);font-size: 12px;">
      我已经阅读并同意 <a href="javascript:void(0);" @click="sprotocol" style="color:#00DE93">《服务协议》</a><a href="javascript:void(0);" @click="pprotocol" style="color:#00DE93">《隐私协议》</a>
    </div>

    <van-popup v-model="sprotocol_show" position="bottom" round :style="{ height: '80%' }">
      <pre style="margin:30px 10px;white-space: pre-wrap;">
微点云助教服务协议

 

感谢您使用上海白鹿信息科技有限公司（简称“微点云助教”）提供的服务

 

【重要提示】

在使用微点云助教提供的各项服务之前，请您仔细阅读、理解《微点云助教服务协议》（简称“本协议”），特别是以黑体加粗、下划线标识等其他合理方式提示您注意的可能与您利益有重大关系的条款，包括但不限于相关免除责任的条款、对权利进行限制的条款、法律适用和争议解决条款等。

如果您不同意本协议的任意或全部条款，尤其是前述条款，请立即停止注册流程。一旦您通过网络页面点击确认或事实上使用微点云助教提供的任意一项服务（包括免费试用服务），均视为您已阅读、理解并同意签署本协议，并自愿接受并遵守本协议的全部约定。

 

如对本协议内容有任何疑问、意见或建议，您可通过客服电话（18502199809）与我们联系。

 

第一条 通则

1.1用户：指注册、登录、使用本协议约定服务的个人或组织，在本协议中更多地称为“您”。其中，向其他用户提供商品或服务的个人或组织，下文或称为“商家”。

1.2微点云助教服务：指微点云助教基于互联网，通过微点云助教网站（域名：wedots.com）、客户端等提供的各项服务（具体以微点云助教实际提供为准），您可以根据自己的需求选择使用一项或多项具体服务并遵守其服务规则（以下或简称“本服务”） 。

1.3【主体信息】微点云助教是中立的技术服务提供者，依约向您提供各类技术产品和服务；您的网站、应用、软件、平台等任何产品、服务及相关内容等，由您自行运营并承担全部责任。

1.4【主体转让】微点云助教可将本协议部分或全部权利义务转让予第三方，但微点云助教须提前90天书面通知您。为本款生效之需要，双方同意签订所有合理必要的文件及采取合理必要的措施。

1.5【资质要求】您保证已经依照国家相关规定获得了合法经营资质或政府审批等，并在本协议有效期内持续保持具备国家相关规定要求的经营资质或审批手续。

 

第二条 账号注册和使用

2.1【注册资格】您应当具备中华人民共和国法律规定的必要、适当的民事权利能力和民事行为能力，按照微点云助教的要求完成注册、获得微点云助教账号（以下简称“账号”）。若您不具备前述与您行为相适应的民事权利能力和民事行为能力，则您需要在您监护人的陪同下完成注册，并且您与您的监护人应依照法律规定承担因此而导致的一切后果。

为保护账号的安全性和独立性，避免出现账号归属不清晰等不良后果，您应当使用您享有合法权益的手机号码、微信账号或企业微信账号完成注册或授权登录。

2.2 【资料填写】您应当按照微点云助教的要求填写、提交真实、合法、有效的资料（统称“客户资料”），包括但不限于联系人姓名、电子邮箱、联系电话、联系地址、工商登记证件等。您理解并同意，您有义务及时更新，保持您提供信息的真实性及有效性。因您未及时更新因此产生的一切后果，微点云助教不承担任何责任。

2.3【账号信息承诺】您在设置账号及相关功能的名称、头像等信息时，不得侵犯他人商标权、著作权，亦不得违反公序良俗及法律法规要求。

2.4【账号行为责任自负】账号将作为您使用微点云助教服务的身份识别依据，您应当对用户名、密码等信息采取必要、有效的保密和安全保护措施（包括但不限于：保管使用权限、设置高强度密码和定期更换措施等），否则，导致的后果由您自行承担。此外，您应为账号下的行为负责，所有账号下的操作行为均被视为您实施，且应由您承担全部法律后果。

2.5【账号授权】您需规范对账号的使用与管理。若您基于自身经营需要等原因，将账号授权您的员工或他人管理的，须做好权限管控，并且在遇到人员变动时及时完成交接和账号安全保护（例如修改密码、变更登录方式、设置账号保护等）。

2.6【真实身份信息认证】为使您更好地使用微点云助教的各项服务，保障您的账户安全，您应依照国家法律法规、服务规则完成真实身份信息认证。您同意授权微点云助教使用第三方核实您提交的相关信息或资料。

2.7【账号变更】您账号的所有权归微点云助教，使用权归您。若您需变更账户注册信息（包括但不限于变更营业执照、身份证信息、绑定微信公众号等），您应向微点云助教提交符合要求的书面承诺函并提交相关资料，经微点云助教审核同意后方可变更。您保证因承诺函及提交的资料虚假、无效和不准确所导致的法律风险并由此给微点云助教所造成的一切损失均由您自行承担。

2.8【账号归属认定】您认证的真实身份信息是对账号归属以及责任承担等进行判断的依据，在账号出现纠纷时，微点云助教有权认定该账号归属于真实身份信息认证主体，由其承担相应法律责任。对于归属存在争议的账号，微点云助教有权暂时对该账号进行冻结。您在进行认证时务必谨慎对待，给与足够的重视并确保认证主体与账号使用主体保持一致，避免将企业使用的账号认证在个人名下，或将本人使用的账号认证在他人名下等任何不当认证行为。

2.9【提交资料须知】若您发现有他人盗用您的账号及密码、或任何其他未经您合法授权的情形时，您应立即以有效方式通知微点云助教并提供必要资料（如客户资料、情况说明、证明材料及诉求等，以便微点云助教核实身份及事件）。微点云助教收到您的有效通知并核实身份后，会依据法律法规及服务规则进行处理。

若您提供的资料存在瑕疵，导致微点云助教无法核实您的身份或无法判断您的需求等，而导致微点云助教未能及时处理，给您带来的损失，应由您自行承担。同时，您理解微点云助教对您的任何请求采取行动均需要合理时间，且微点云助教应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除微点云助教存在法定过错外，微点云助教不承担责任。

 

第三条 服务购买与支持（本条款仅适用于商家）

3.1【服务在线购买】商家可通过微点云助教网站或客户端在线选购所需服务，购买前商家需仔细阅读所购服务对应的服务规则，并根据自身需求决定是否购买或使用。

3.2【服务协议的补充】部分微点云助教服务的开通可能需要双方另行签订单独的服务协议，单独的服务协议可能以电子文档形式展示，也可能是独立的纸质文档形式展示，商家可以根据自身情况决定是否接受服务协议及开通服务。

3.3【优惠措施说明】微点云助教可能因业务运营、产品推广等各种原因，在特定时期内推出各项优惠措施（以下统称“优惠措施”），商家理解并同意，这些优惠措施可能是暂时性、阶段性或有数量限制的，也可能仅适用于满足特定条件的客户，商家需要按照相应的规则购买、使用相应服务。除非另有书面明确说明，优惠措施不可同时叠加适用。

3.4【服务开通】为保证服务及时开通或持续提供，商家应当遵守本协议及商家使用的服务的服务规则并及时缴纳费用。具体费用以交易进行时，商家进入微点云助教官网相关交互界面时载明的费用为准。部分服务可能有时间限制、库存限制或者活动数量等限制，订单提交后，若发生商家未及时付款、或付款期间出现数量不足或已达到限量等情况的，商家将可能无法使用相关服务。

3.5【付款方式】商家可以通过账号在线扫码付款的形式付费，也可以通过对公银行转账方式向微点云助教支付费用（为保证服务的持续性，若商家选择对公银行转账支付费用的，应预留合理时间以便微点云助教核实到账情况并开通服务）。

3.6【服务费用调整】微点云助教可能根据实际需要对收费服务的收费标准、方式进行修改和变更，微点云助教也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，微点云助教会采取合理途径并以足够合理的期限提前通过站内消息、网页公告等方式通知商家，确保商家有充分选择的权利。

3.7【逾期付费的责任】商家应当按照服务规则及时、足额支付费用。若商家未及时、足额支付费用的，微点云助教有权拒绝开通服务或无需另行通知而中止、终止服务，同时可以采取以下一项或多项措施：

（1）要求商家除应依约支付应付费用外，每逾期一天，还应按所欠费用1‰的标准向微点云助教支付违约金，直至缴清全部费用为止。

（2）若商家逾期付款超过15天，微点云助教有权无需另行通知商家即可随时采取包括但不限于提前单方解除协议的措施。

（3）若商家逾期付款，微点云助教有权无需另行通知商家，而直接将预付费用和账户余额（如有）用于抵扣拖欠款项和违约金。

3.8【第三方服务补贴】商家使用的部分功能，需要向第三方运营商支付相关服务费用，在商家满足相应服务规则时，该服务费用可能由微点云助教进行补贴。微点云助教有权视情况需要，调整上述服务费补贴政策，具体以相应的服务规则为准。

3.9【涉外业务】若商家在中国大陆以外使用微点云助教服务，应当确保商家符合中国大陆法律法规、政策等的要求，同时，也应当确保商家的资质、能力以及使用行为等均符合当地法律法规、政策等的要求。

3.10【人员管理】商家对其所有的服务人员负有管理责任，商家作为管理者，应当对其服务人员发布的内容及行为负责，并承担法律监管责任。

 

第四条 用户交易规范

4.1【下单】当您在微点云助教提供技术支持的服务中购买其他用户提供的商品及/或服务时，请您务必仔细确认所购商品或服务的详细信息（如价格、数量、规格、限制性要求等），并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。

您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱微点云助教正常交易秩序的行为。

4.2【评价】您有权在微点云助教提供的评价系统中对与您达成交易的其他用户商品及/或服务进行评价。您应当理解，您在微点云助教的评价信息是公开的。

为确保评价内容能反映商品及/或服务的真实情况，您的所有评价行为应遵守服务规则的相关规定，评价内容应当合法、客观、真实，与交易的商品及/或服务具有关联性。

您的评论内容不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；也不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索。否则，微点云助教可按照本协议的相关规定对您实施上述行为所产生的评价信息采取必要处置措施。

4.3【交易争议处理】您与其他用户在交易过程中发生争议的，您有权选择以下途径解决：

（1）与争议相对方自主协商；

（2）使用微点云助教提供的争议处理服务；

（3）请求消费者协会或者其他依法成立的调解组织调解；

（4）向有关行政部门投诉；

（5）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；

（6）向人民法院提起诉讼。

如您使用微点云助教的争议处理服务，则表示您认可并愿意履行微点云助教作为独立的第三方根据其所了解到的争议事实并依据微点云助教相关服务规则所作出的处理决定。在微点云助教调处决定作出前，您可选择4.3款的（3）、（4）、（5）、（6）途径（下称“其他争议处理途径”）解决争议以中止微点云助教的争议处理服务。

如您对处理决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行处理决定。

 

第五条 第三方投诉处理

为合理保护您与权利人的利益，微点云助教有权制定专门的侵权投诉流程制度，您应当予以遵守。如果微点云助教接到第三方对您的投诉或举报，微点云助教有权向第三方披露您相关资料（如您的主体资料及您就投诉或举报提交的反通知书、相关证据等全部资料），要求您与投诉方进行协商（包括但不限于建立包含您、微点云助教以及投诉方的三方邮件组，供您、投诉方直接沟通、相互举证等，下同），若您投诉或举报其他微点云助教客户的，微点云助教也有权向被投诉方披露您相关资料（如您的主体资料及您就投诉或举报提交的通知书、相关证据等全部资料），要求您与被投诉方进行协商，以便及时解决投诉、纠纷，保护各方合法权益，您应当予以配合，否则，可能会影响您继续使用微点云助教服务，由此造成您或他方损失的，您自行承担。

 

第六条 用户行为规范

6.1【禁止性行为（一）】您在使用微点云助教服务时须遵守相关法律法规及服务规则，并确保拥有法律法规要求的经营资质和能力，不得实施包括但不限于以下行为，也不得为任何违反法律法规的行为提供便利：

（1）反对宪法所确定的基本原则的；

（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；

（3）损害国家荣誉和利益的；

（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；

（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；

（6）煽动民族仇恨、民族歧视，破坏民族团结的；

（7）破坏国家宗教政策，宣扬邪教和封建迷信的；

（8）散布谣言，扰乱经济秩序和社会秩序的；

（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；

（10）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；

（11）中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其他内容。

6.2【禁止性行为（二）】您在使用微点云助教服务时须维护互联网秩序和安全，不得侵犯任何主体的合法权益，不得实施包括但不限于以下行为，也不得为其提供便利：

（1）实施诈欺、虚伪不实或误导行为，或实施侵害他人知识产权等任何合法权益的行为。

（2）发布、传播包含危害国家秩序和安全、封建迷信、淫秽、色情、低俗等违法违规信息。

（3）实施任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，对网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等。

（4）实施任何改变或试图改变微点云助教服务提供的系统配置或破坏系统安全的行为；利用技术或其他手段破坏、扰乱微点云助教服务的运营或他人对微点云助教服务的使用；以任何方式干扰或企图干扰微点云助教任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等。

（5）申请和注册与微点云助教及微点云助教关联方相同或近似的商标、名称、标识、标志、微信公众号、域名、网页等，用于开展与微点云助教及微点云助教关联方实质相同或近似的业务。

6.3【禁止性行为（三）】您在使用微点云助教服务时，不得发布含有下列内容的不良信息：

（1）使用夸张标题，内容与标题严重不符的；

（2）炒作绯闻、丑闻、劣迹等的；

（3）不当评述自然灾害、重大事故等灾难的；

（4）带有性暗示、性挑逗等易使人产生性联想的；

（5）展现血腥、惊悚、残忍等致人身心不适的；

（6）展示危害自身或他人生命安全的行为或言论

（7）煽动人群歧视、地域歧视等的；

（8）宣扬低俗、庸俗、媚俗内容的；

（9）可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；

（10）宣扬不良、消极颓废的人生观、世界观和价值观的，违背伦理道德的。

（11）其他有违公序良俗、有悖于社会公德或对网络生态造成不良影响的内容。

6.4您保证：不对平台、技术接口、本服务数据进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）；不对平台、技术接口、本服务数据进行内容、功能、逻辑及界面等任何方面的修改；不得将平台、技术接口、本服务数据或业务用于本协议以外目的。

 

第七条 知识产权

7.1【知识产权独自享有】双方各自享有的商标权、著作权或者其他知识产权，均仍归各方单独享有，并不会因为双方签订或者履行本协议而转归对方享有，或者转归双方共同享有。

7.2【尊重第三方】任何一方均应尊重相对方、第三方的知识产权，未经相关权利人许可，不得以任何形式进行使用或创造相关衍生作品。

7.3【商业标识】微点云助教在本服务中所使用的“微点云助教”、微点云助教表情包及LOGO等商业标识，其著作权或商标权归微点云助教所有。

7.4【网站相关权属】微点云助教官网上由微点云助教上传、制作、拥有的所有内容，包括但不限于文字、图片、网站架构、网页设计，均由微点云助教及其关联公司依法拥有其知识产权。

7.5【软件相关权属】除另有特别声明外，微点云助教提供本服务时所依托软件的著作权、专利权及其他知识产权均归微点云助教所有。

7.6【技术相关权属】您仅拥有本协议约定合法使用本服务或微点云助教提供相关技术接口的权利，与本服务相关的技术接口、相关的著作权、专利权等相关全部权利归微点云助教所有。未经微点云助教许可，您不得违约或违法使用，不得向任何单位或个人出售、转让、授权微点云助教的代码、技术接口及开发工具等。

7.7【知识产权侵权处理】未经微点云助教或权利人明确的书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用微点云助教网站或客户端的信息内容，否则，微点云助教有权追究其法律责任。

7.8【商业使用】商家同意：微点云助教有权无偿使用商家的名称、品牌、商标标识等作为商业案例进行宣传、展示。

 

第八条 数据与安全

8.1【个人信息的保护】未经您的同意，微点云助教不会向微点云助教以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。

8.2【未成年人特别约定】微点云助教非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用微点云助教的服务前，应事先取得您法定监护人的书面同意。

8.3【尊重个人信息】您应对通过本服务了解、接收或可接触到任何人的个人信息予以充分尊重。未经明示授权同意，您不应以搜集、复制、存储、传播或以其他任何方式使用第三方的个人信息，否则，由此产生的后果由您自行承担。

您在必要情况下若需要收集、保存或使用任何个人信息的，必须事先获得对方的明确同意，且仅应当收集为运营及功能实现目的而必须的个人信息，同时应告知对方相关信息收集的目的、范围及使用方式等，保障知情权和选择权。

8.4【数据使用】您在数据的收集、处理、存储、使用过程中应遵守国家相关法律法规的要求或与第三方合同的约定，否则由此造成的全部结果及责任均由您自行承担，并且，微点云助教有权随时全部或部分终止向您提供微点云助教服务。您因服务终止遭受的一切损失或承担的一切责任和费用，由您自行承担。

8.5【数据删除与更改】您应谨慎进行本服务中的数据删除、更改等处理操作，并承担因该等操作所产生的全部后果。

8.6【数据备份】您应根据自己的需要自行备份本服务中的相关数据，微点云助教暂未提供数据备份服务。如果您停止使用本服务或服务被终止或取消，微点云助教可以从服务器上永久地删除您的数据。在服务停止、终止或取消后，微点云助教没有义务向您返还任何数据。

8.7【安全保护措施】微点云助教将依照法律法规要求对其系统、设备等采取基础的安全保护措施。除因您使用微点云助教服务所需及法律法规另有规定外，未经您同意，微点云助教不会接触或使用您的数据。

您应自行对您使用的计算机信息系统、设备等采取必要的、有效的安全保护措施，因您未采取前述措施导致权益受损的，由您自行承担责任。

 

第九条  服务终止与责任承担

9.1【服务终止情形】发生下列情形，服务期限终止：

（1）双方协商一致终止的；

（2）您违反法律法规或本协议约定，微点云助教有权终止服务,并不退还您已经支付的费用。

9.2【运营调整】微点云助教有权根据自身运营安排，随时调整、终止部分或全部服务（包括但不限于对服务进行下线、迭代、整合等）。但是，微点云助教应在合理期限内通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。

9.3【违约责任】如果微点云助教发现或收到他人投诉您违反法律法规及本协议约定的，微点云助教有权不经通知随时对相关内容进行删除、下架，并视行为情节对违规账号处以包括但不限于限制或禁止使用部分或全部功能、账号封禁的处罚，并公告处理结果。微点云助教也有权依照本协议的规定，拒绝再向该主体提供服务。

微点云助教依据本协议采取相应措施（包括但不限于暂停服务、终止服务等），不视为微点云助教违约，若因此给您造成损失（包括但不限于业务暂停、数据清空等）的，均由您自行承担。同时，因您违反本协议约定产生的责任和后果由您自行承担，若给微点云助教造成损失的，您应当予以赔偿。

9.4【第三方索赔】若因您违反本协议的保证与承诺，导致或产生第三方主张的任何索赔、要求或损失，应当自行负责处理由此产生的争议，并承担全部法律责任，若微点云助教因此遭受损失的，您应当一并赔偿。

9.5【特殊限制措施】当您的行为存在侵权、欺诈、售假、盗用他人账户等特定情形或您存在危及他人交易安全或账户安全风险时，微点云助教可对您采取冻结资金、锁定提现等强制措施。

 

第十条 免责声明 

10.1【不可抗力及第三方原因】您理解并同意，在使用本服务时，须自行承担如下微点云助教不可掌控的风险内容，包括但不限于：

（1）不可抗力，包括但不限于自然灾害、政府行为、法律法规颁布调整、罢工（任一方内部劳资纠纷除外）、动乱等不能预见、不能避免并不能克服的客观情况。

（2）基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护。

（3）网络安全事故，如计算机病毒、木马或其他恶意程序、黑客攻击的破坏。

（4）您或第三方的电脑软件、系统、硬件和通信线路出现故障。

（5）您操作不当或通过非微点云助教授权的方式使用本服务带来的损失和风险。

（6）因您未及时续费，导致本服务不能提供或提供时发生任何错误。

（7）其他非微点云助教过错、微点云助教无法控制或合理预见的情形。

10.2【违约责任免除】因不可抗力、基础运营商原因、网络安全事故或其他超出当事人可合理掌控范围的事件，造成本协议迟延履行或任何一方违约，双方均无需承担违约责任。但是，受影响一方应尽可能及时通知另一方。如前述事件妨碍协议履行达30天以上的，任一方可提前15天书面通知对方终止协议。因本条款终止协议的，任何一方均无须承担违约责任。

10.3【特定服务免责】若您使用的是微点云助教基础版或成长版服务，无论任何原因导致服务中止、终止的，微点云助教均不承担任何违约、补偿、赔偿责任。

10.4【技术瑕疵免责】您理解并同意，微点云助教的服务是按照现有技术和条件所能达到的现状提供的。微点云助教将尽最大努力确保服务的连贯性和安全性，但微点云助教不能保证其所提供的服务毫无瑕疵，因此，即使微点云助教提供的服务存在瑕疵，但若上述瑕疵是当时行业技术水平所无法避免的，其将不视为微点云助教违约，微点云助教也无需承担任何责任，双方应友好协作共同解决问题。

10.5【服务维护免责】为提供更完善的服务，微点云助教有权定期或不定期地对服务平台或相关设备、系统、软件等进行检修、维护、升级及优化等。微点云助教将提前向您通知维护和检测的信息，因此产生的服务中断或不稳定状态，不视为微点云助教违约，但微点云助教有义务将此种中断或不稳定状态限制在合理时间内。

10.6【功能更新免责】您理解并同意，为了改善用户体验、完善服务内容，微点云助教将不断努力开发新的功能，并为您不时提供内容更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。并且微点云助教有权基于运营和交易安全，对服务的部分功能效果进行改变或限制。

 

第十一条 通知和送达

11.1【通知送达】本协议项下微点云助教对于您的所有通知均可通过网页公告、站内消息、公众号通知、客户预留的电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达。

11.2【联系方式实时更新】为便于您收取相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录微点云助教修改，如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。

11.3【有效阅读通知】微点云助教可通过上述方式之一或其中若干向您送达各类通知，而此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注相应通知。

11.4【消息推送】您同意微点云助教可以向您的电子邮件、手机号码等发送可能与本服务不相关的其他各类信息包括但限于平台规则信息、活动信息、交易信息、商业广告等。

 

第十二条 法律适用、争议解决与其他

12.1【协议更新】您使用本服务即视为您已阅读并同意受本协议的约束。微点云助教可能根据国家法律法规变化、业务调整、产品更新及维护交易秩序、保护消费者权益等需要对协议内容进行变更，变更后的协议内容将按照法定程序进行公示后生效，您可以在微点云助教官网中查阅相关协议条款。如您不同意变更的内容，您应当于本协议变更生效之日起停止使用本服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用本服务，则视为您同意变更后的协议。

12.2【协议签订地】本协议签订地为中华人民共和国上海市宝山区。

12.3【法律适用】本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。

12.4【管辖】若您和微点云助教之间发生任何纠纷或争议，应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。

12.5【商业贿赂】如您向微点云助教及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，微点云助教可立即终止与您的所有合作，若造成微点云助教损失的，您应当一并赔偿。

12.6【署名】您应在您使用微点云助教服务的应用界面显著位置标识“微点云助教提供技术支持”或微点云助教告知您的其他商业标识，以保障微点云助教作为技术服务商的署名权。

12.7【可分性】本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。

12.8【标题】本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。

 
      </pre>  
    </van-popup>

    <van-popup v-model="pprotocol_show" position="bottom" round :style="{ height: '80%' }">
    <pre style="margin:30px 10px;white-space: pre-wrap;">
微点云助教个人信息保护政策

 

更新日期：2022年08月08日

生效日期：2022年08月08日

 

版本更新提示：

为更充分保障您的权利，微点云助教服务提供者（或简称“我们”）对《微点云助教个人信息保护政策》进行了更新，此版本更新主要集中于：

1.在“3.1我们如何收集和使用您的个人信息”部分，更详细地说明了您在使用我们产品过程中，我们收集您个人信息的场景。

2.在“3.2我们如何委托处理、共享、转让、公开披露您的个人信息”部分，进一步明确了《微点云助教第三方SDK目录清单》，新增《应用权限申请与使用情况说明》以及《数据共享情况说明》。

为了更好的保护您的个人信息，建议您仔细阅读更新后的《微点云助教个人信息保护政策》，尤其是以黑色加粗的条款。如您对本政策条款有任何异议或疑问，您可通过本政策第九条“联系我们”中公布的联系方式与我们沟通。 

 
《微点云助教个人信息保护政策》目录

一、引言

二、关于我们

三、个人信息收集使用规则

四、我们如何存储和保护您的个人信息

五、您如何行使您的个人信息权利

六、我们如何保护未成年人的个人信息

七、我们如何使用 Cookie 和同类技术

八、本政策如何更新

九、如何联系我们

十、争议解决

 

一、引言
1.1《微点云助教个人信息保护政策》适用于微点云助教向您提供的所有产品和服务，包括但不限于微点云助教网站、微点云助教小程序。

1.2微点云助教服务中可能部分由我们的关联公司提供，但未设独立的个人信息保护政策，本政策同样适用于该部分服务。

1.3请您在使用微点云助教各项产品和/或服务前，仔细阅读并充分理解本政策的全部内容。一旦您使用或继续使用微点云助教的服务，即表示您同意我们按照本政策使用和处理您的相关信息。

1.4您需理解，只有在您确认并同意变更后的《微点云助教个人信息保护政策》，我们才会依据变更后的政策收集、使用、处理和存储您的个人信息。您有权拒绝同意变更后的政策，但请您知悉，一旦您拒绝同意变更后的政策，可能导致您不能或不能继续完整使用微点云助教的相关服务和功能，或者无法达到我们拟达到的服务效果。

 

二、关于我们
2.1微点云助教产品和相关服务由上海白鹿信息科技有限公司提供。

2.3我们的主要运营主体的基本信息如下：上海白鹿信息科技有限公司，成立于2014年09月17日，办公地址：上海市杨浦区国定支路26号3250室，联系电话：18502199809。

 

三、个人信息收集使用规则
3.1我们如何收集和使用您的个人信息
为了向您提供服务、保障服务的正常运行、改进和优化我们的服务，微点云助教会遵循合法、正当、必要和诚信原则收集和使用您在使用产品/服务过程中主动提供或由于产品/服务需要而产生的个人信息。我们收集和使用您的个人信息类型包括两种：

（1）我们产品/服务的核心业务功能所必需的信息，此类信息为产品/服务正常运行的必要信息，你须授权我们收集和使用。如您拒绝，您将无法正常使用我们的产品/服务。

（2）非核心业务功能可能需要收集的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致某些功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。

 

通常情况下，我们会在以下场景中收集和使用您的个人信息：

3.1.1【账号注册/登录】

（1）当您在创建微点云助教账号时，您须提供手机号码和登录密码。您提交的手机号码用于您注册、登录、绑定账号、密码找回时接受验证码，并且作为您与微点云助教指定的联系方式之一，用来接受相关营销及业务通知（包括电话营销、商业性信息、新品上线、服务变更操作），并且微点云助教可通过此联系方式向您发送业务通知（含账单）或与您进行业务沟通（“安全手机号码”或“账号联系人手机号码”）。

收集手机号码是为了满足相关法律法规的网络实名制要求，我们将通过发送短信验证码的方式验证您的身份，若您不提供这类信息，您可能无法正常使用我们的核心业务服务。

（2）我们的产品支持您使用微信账号进行登录，如您使用微信账号登录的，我们将根据您的授权获取微信账号下的相关信息（包括：昵称、头像及其他页面提示的信息）。我们承诺，收集上述信息是用于为您提供账号登录服务。如您拒绝授权此类信息，则您将无法使用微信账号登录微点云助教。


3.1.3【客服及争议处理】

当您向我们发起投诉、举报或进行咨询时，为了保障您的账号及系统安全，我们可能需要您提供必要的个人信息（包括账号信息、订单信息）以核验您的身份。

为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的电话号码、电子邮件地址信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。

3.1.4【商品或服务交易】

当您在我们的产品及/或服务中订购具体商品及/或服务时，您需要提供与完成交易相关联的信息，包括：

（1）交易的商品或服务信息、收货人姓名、收货地址、收货人联系电话（敏感个人信息）。

（2）交易金额、下单时间、订单编号、订单状态、支付方式、支付流水号、支付状态。

我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、便于您查询订单信息、提供客服与售后服务及其他我们明确告知的目的。

当您成功下单后，如商品需要第三方配送的，我们为了顺利、安全、准确向您完成货物的交付及配送，第三方配送公司在发货及配送环节内不可避免地会获知您的相关配送信息（敏感个人信息）。您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。

3.1.5【学习信息】

当您使用我们的产品或服务进行课程学习时，我们会记录您的学习信息，包括：学习行为数据、考试结果、学习时长。我们收集这些数据是为了分析您的学习情况。

3.1.6【支付】

当您进行订单扫码支付时，我们会收集您的微信支付渠道的user ID（微信open ID）。上述信息为我们向您提供您购买商品和/或服务所必须的信息，我们无法仅根据该信息获得您的个人财产信息。

3.1.7【信息公开发布功能】

您可通过我们为您提供的评论、消息互动、直播及其他信息发布功能公开发布信息，包括发布图文/视频/直播内容、发表评价及问答内容，以及作为商家发布商品及/或服务相关信息、我们尊重并保护您对相关信息是否发布以及发布范围的设置。

您可自主地、选择性地完善您的网络身份识别信息，包括头像、昵称等。同时，您还可以根据自身的社交需求，选择性地填写姓名、地址、性别、生日、公司、职位等介绍信息以完善您的个人信息。

请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至敏感个人信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及他人个人信息的，您需在发布前征得他人的同意。

3.1.8【基于系统权限的附加服务】

为向您提供更便捷、更优质的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用微点云助教的基本服务，但您可能无法获得这些附加服务给您带来的用户体验。

您可在您的设备“设置”查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。

请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。

您可点击此处查看《应用权限申请与使用情况说明》。

3.2我们如何委托处理、共享、转让、公开披露您的个人信息
3.2.1委托处理

我们的产品中包含第三方SDK（软件工具开发包）或其他类似的应用程序，如您在微点云助教产品上使用这类由第三方提供的服务或功能时，您同意将由其直接收集和处理您的信息。

前述第三方SDK或其他类似的应用程序收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私条款。

您可点击此处查看《微点云助教第三方SDK目录清单》。

3.2.2共享

我们不会与我们关联公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的授权同意。

您可点击此处查看《微点云助教数据共享情况说明》。

3.2.3转让

除非获取您的明确同意，我们不会将您的个人信息转让给任何公司、组织或个人。如果发生合并、收购或破产清算，将可能涉及到个人信息转让，此种情况下我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束。如果本隐私政策中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。

3.2.4公开披露

我们仅会在以下情况下，公开披露您的个人信息：

（1）获得您明确同意后；

（2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。

（3）因需要对违规账号、欺诈行为等进行处罚公告的。

特别提示：对于公开披露的您的个人信息，我们会在收到公开披露申请后第一时间且审慎审查其正当性、合理性、合法性，并在公开披露时和公开披露后采取最严格个人信息安全保护措施和手段对其进行保护。 

3.2.5共享、转让、公开披露您的个人信息的的例外

以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：

（1）与国家安全、国防安全直接相关的；

（2）与公共安全、公共卫生、重大公共利益直接相关的；

（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；

（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；

（5）个人信息主体自行向社会公众公开的个人信息；

（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。

（7）根据法律规定，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。


四、我们如何存储和保护您的个人信息
4.1个人信息存储

（1）我们将按照有关法律法规规定，仅会将收集到的有关您的个人信息存储于中华人民共和国境内。目前我们暂时不存在跨境存储您的个人信息或向境外提供个人信息的场景。

（2）我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。

（3）在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。

4.2个人信息保护

（1）我们使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改，防止数据发生损坏或丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理等手段来保护你的个人信息。

（2）我们建立专门的管理制度、流程和组织确保您的信息安全。如部署访问控制机制，严格限制访问个人信息的人员范围等。

（3）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内存储您的个人信息，除非需要延长存储期或受到法律的允许。

（4）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。

（5）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。

（6）同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
 

五、您如何行使您的个人信息权利
5.1您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：

网页端：登录微点云助教账号，进入“学员中心”，点击“设置”后查询、更正个人资料及个人账号相关信息。

如果您无法通过上述方式更正信息的，可通过本政策第九条联系我们的客服处理，我们将在15个工作日内反馈结果，并后台将同步更正相关信息。

5.2您有权删除您的个人信息：

在以下情形中，您可通过第九条的联系方式向我们提出删除个人信息的请求： 

（1）如果我们处理个人信息的行为违反法律法规；

（2）如果我们收集、使用您的个人信息，却未征得您的同意；

（3）如果我们处理个人信息的行为违反了与您的约定；

（4）如果您不再使用我们的产品或服务，或您注销了账号；

（5）如果我们不再为您提供产品或服务。

收到您的删除请求后，我们会根据您的要求进行删除处理并在15个工作日内向您反馈结果（法律法规要求必须保留的数据除外）。

当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应信息，但会在备份更新时进行删除。

5.3您有权改变您授权同意的范围：

每个业务功能需要一些基本的个人信息才能得以完成，对于额外收集的个人信息的收集和使用，您可以给予或收回您的授权同意。

如果您希望变更您的授权范围，可通过本政策第九条联系我们的客服处理。

5.4您有权通过如下方式注销账号：

如需注销账号，您可以通过本政策第九条联系我们的客服申请注销。

在您主动注销账号之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除您的个人信息，或对其进行匿名化处理。

5.5个人信息主体获取个人信息副本 

您可以通过本政策第九条联系我们的客服申请获取您的个人信息副本，客服在完成对您的身份核验后，将向您提供个人信息副本。

5.6约束信息系统自动决策 

在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。 

5.7响应您的上述请求

为保障安全，您通过联系客服的方式提出上述请求时需要提供书面文件，或以其他方式证明您的身份。我们将要求您验证自己的身份后响应您的请求。

我们对于您的书面请求响应时间为15个工作日。

对于您合理的请求，我们可免费提供服务，如涉及第三方费用，在我们为您提供相应证据后由您自行承担相关成本。请注意，针对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。

5.8在以下情形中，我们将无法响应您的请求：

（1）与个人信息控制者履行法律法规规定的义务相关的；

（2）与国家安全、国防安全直接相关的；

（3）与公共安全、公共卫生所所生、重大公共利益直接相关的；

（4）与刑事侦查、起诉、审判和执行判决等直接相关的；

（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；

（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；

（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；

（8）涉及商业秘密的。


六、我们如何保护未成年人的个人信息
我们非常重视对未成年人个人信息的保护，根据相关法律法规的规定，若你是14周岁以下的未成年人，在你使用微点云助教服务前，除仔细阅读并了解本隐私声明外，你还应仔细阅读微点云助教专门制定的《儿童隐私保护声明》，并取得你的家长或法定监护人对本隐私声明与《儿童隐私保护声明》的书面同意。若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过第九条的联系方式与我们联系。

七、我们如何使用 Cookie 和同类技术
7.1在您使用我们的产品与/或服务时，我们可能会使用Cookie和同类技术收集您的一些个人信息，包括：您的登录信息，Cookie和同类技术收集该类信息是为了您使用我们的产品与/或服务的必要、简化您重复操作的步骤（如注册、登录）、保护您的信息和账号安全性、提升我们的产品和服务等。

7.2如果您拒绝我们使用Cookie及同类技术收集和使用您的相关信息，您可在浏览器具备该功能的前提下，通过您的浏览器的设置以管理、（部分/全部）拒绝Cookie与/或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的Cookie与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。

您理解并知悉：我们的某些产品/服务只能通过使用Cookie或同类技术才可得到实现，如您拒绝使用或删除的，您可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。

八、本政策如何更新
为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求更新《微点云助教个人信息保护政策》的条款，该等更新构成《微点云助教个人信息保护政策》的一部分。未经您明确同意，我们不会削减您按照本政策所应享有的权利。如该等更新造成您在本《微点云助教个人信息保护政策》下权利的实质减少或重大变更，我们将在本政策生效前通过在显著位置提示或向您发送推送消息或以其他方式通知您，若您继续使用我们的服务，即表示您充分阅读、理解并同意受经修订的《微点云助教个人信息保护政策》的约束。

重大变更包括但不限于：

（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；

（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；

（3）个人信息共享、转让或公开披露的主要对象发生变化；

（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；

（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；

（6）个人信息安全影响评估报告表明存在高风险时。

 
九、如何联系我们
如您对《微点云助教个人信息保护政策》的内容或使用我们的服务时遇到的与个人信息保护相关的事宜有任何疑问或进行咨询或投诉时，您均可以通过如下任一方式与我们取得联系：

（1）您可通过关注“微点云助教”微信公众号，点击“联系客服”与我们联系；

我们会在收到您的意见及建议后，并在验证您的用户身份后的15个工作日内作出答复。需注意的是，我们可能不会回复与本政策或与个人信息保护无关的问题。

十、争议解决
1. 本隐私政策的解释及争议解决均应适用中华人民共和国大陆地区法律。任何因本隐私政策以及我们处理您个人信息事宜引起的争议，您同意向被告住所地有管辖权的法院提起诉讼。

2. 如果您认为我们处理个人信息的行为损害了您的合法权益，您也可以选择向有关政府部门进行反映。

3. 本隐私政策的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。
</pre>
    </van-popup>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import QueryString from 'qs'
import store from '@/store/index'
import { Field , Popup} from 'vant'
import { isPhone, isEmpty } from '@/common/validate'
import {
  saveToken
} from '@/utils/cache'

var vcodeTimer
export default {
  name: 'oauth',
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup
  },
  mixins: [PageMixin],
  data() {
    return {
      brandInfo: {},
      phone: '',
      vcode: '',
      sprotocol_show: false,
      pprotocol_show: false,
      vcodeSend: 0, // 0获取验证码,1验证码倒计时,
      vcodeText: '获取验证码'
    }
  },
  created() {
    let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 深拷贝
    delete newQuery.code
    delete newQuery.state
    if(newQuery.app){
      delete newQuery.appid
    }
    this.$router.replace({ query: newQuery })
    this.querybrandIndex()
  },
  methods: {
    sprotocol(){
      this.sprotocol_show = true;
    },
    pprotocol(){
      this.pprotocol_show = true;
    },
    querybrandIndex() {
      this.$api.brand_index().then(res => {
        const info = res.data
        this.brandInfo = info
      })
    },
    onClsoe(){
      wx.closeWindow()
    },
    async onSubmit() {
      let query = this.$route.query;
      let appid = localStorage.getItem('appid');
      let jump_url = `https://h5.wedots.cn/h5/auth/redirect?path=${query.path}&appid=${query.appid}&guest_allow=`
      if(query.guest_allow){
       jump_url = `https://h5.wedots.cn/h5/auth/redirect?path=${query.path}&appid=${query.appid}&guest_allow=${query.guest_allow}`
      }
      for(let k in query){
        if(k!='app' && k!='appid' && k!='path' && k!='state' && k!='code'){
          jump_url += '&'+ k +'='+ query[k]
        }
      }
      await store.dispatch('user/setLoginStatus', 1)
      window.location.href = jump_url
    }
  }
}
</script>

<style lang="less" scoped>

  .header{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 250px;
    color: #fff;
    padding: 0 15px;
    // background-color: #fe4140;
    background: @linear-gradient-color;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-avatar{
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
      width: 116px;
      height: 116px;
      background: rgba(255,255,255, .5);
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 5px rgba(255,255,255, 1);
      &-img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .form{
    padding: 0 30px;
  }
  .btn-area{
    margin-top: 20px;
  }
  .container{
    box-sizing: border-box;

    height: 100vh;
    background-color: #fff;
    // background-image: url(~@/assets/apply_bg.png);
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
</style>